.App {
    background-image: url('../src/resources/background1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.footer {
    position:fixed;
    left:0;
    bottom:0;
    right:0;
}

a {
    text-decoration: underline; 
    color: #ffffff; 
  }

.Nav {
    position: fixed;
    margin: auto;
    width: 50%;
    left: 25%;
    padding: 10px;
    z-index: 1500;
}

.NavMobile {
    margin: auto;
    width: 100%;
}



video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index:-100;
  }